import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("Login")
  }, {
    path: '/logout',
    name: 'Logout',
    component: lazyLoad("Logout")
  }, {
    path: '/logout/:username/:password',
    name: 'Logout',
    component: lazyLoad("Logout")
  }, {
    path: '/forget_password/',
    redirect: '/',
  }, {
    path: '/forget_password/:code',
    name: 'ForgetPassword',
    component: lazyLoad("ForgetPassword")
  }, {
    path: '/invite/:type/:code',
    name: 'InviteToClaim',
    component: lazyLoad("InviteToClaim")
  }, {
    path: '/Member',
    component: lazyLoad("Member/Base"),
    children: [
      {
        path: '',
        name: 'MemberProfile',
        component: lazyLoad("Member/Profile")
      },
      {
        path: 'profile',
        name: 'MemberProfile',
        component: lazyLoad("Member/Profile")
      },
      {
        path: 'information',
        name: 'MemberInformation',
        component: lazyLoad("Member/Information")
      },
      {
        path: 'password',
        name: 'MemberPassword',
        component: lazyLoad("Member/Password")
      },
      {
        path: 'approval',
        name: 'MemberApproval',
        component: lazyLoad("Member/Approval")
      },
      {
        path: 'gallery',
        name: 'MemberGallery',
        component: lazyLoad("Member/Gallery")
      },
      {
        path: 'products',
        name: 'MemberProducts',
        component: lazyLoad("Member/Products")
      },
      // {
      //   path: 'referral',
      //   name: 'MemberReferral',
      //   component: lazyLoad("Member/Referral")
      // },
      // {
      //   path: 'earning',
      //   name: 'MemberEarning',
      //   component: lazyLoad("Member/Earning/Base"),
      //   children: [
      //     {
      //       path: '',
      //       redirect: 'company_earning'
      //     },
      //     {
      //       path: 'company_earning',
      //       name: 'MemberEarningCompanyEarning',
      //       component: lazyLoad("Member/Earning/CompanyEarning")
      //     },
      //     {
      //       path: 'discover_more',
      //       name: 'MemberEarningDiscoverMore',
      //       component: lazyLoad("Member/Earning/DiscoverMore")
      //     },
      //     {
      //       path: 'support_center',
      //       name: 'MemberEarningSupportCenter',
      //       component: lazyLoad("Member/Earning/SupportCenter")
      //     },
      //   ]
      // },
      {
        path: 'earning',
        name: 'MemberEarningDashboard',
        component: lazyLoad("Member/EarningDashboard"),
      },
      {
        path: 'discover_more',
        name: 'MemberDiscoverMore',
        component: lazyLoad("Member/DiscoverMore")
      },
      {
        path: 'support_center',
        name: 'MemberSupportCenter',
        component: lazyLoad("Member/SupportCenter")
      },
      {
        path: 'send_link',
        name: 'MemberSendLink',
        component: lazyLoad("Member/SendLink")
      },
      {
        path: 'send_link/email',
        name: 'MemberSendLinkEmail',
        component: lazyLoad("Member/SendLinkEmail")
      },
      {
        path: 'send_link/text',
        name: 'MemberSendLinkText',
        component: lazyLoad("Member/SendLinkText")
      },
      {
        path: 'contest',
        name: 'MemberContest',
        component: lazyLoad("Member/Contest")
      },
      {
        path: 'subscription',
        name: 'MemberSubscription',
        component: lazyLoad("Member/Subscription")
      },
      {
        path: 'web_cam',
        name: 'WebCam',
        component: lazyLoad("Member/WebCam")
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
