export let member = LocalStorage("_memberData_");

function LocalStorage(name,set=new Object()) {
    var taskArrayString = localStorage.getItem( name );
    if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
    } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem( name, emptyArray );
        taskArrayString = localStorage.getItem( name );
    }
    return JSON.parse(taskArrayString);
}

// localStorage.setItem("_memberData_",JSON.stringify(data));
