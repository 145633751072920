import Vue from 'vue'
import App from './App.vue'

import MainFunc from './MainFunc.vue'
Vue.mixin(MainFunc);

import VueRouter from 'vue-router'
import router from './router'
Vue.use(VueRouter)

import Meta from 'vue-meta'
Vue.use(Meta)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

// import Multiselect from 'vue-multiselect'
// Vue.component("multiselect", Multiselect);

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

// import './../node_modules/bulma/css/bulma.css';
// import './../node_modules/bootstrap-grid-only-css/dist/css/bootstrap-grid.min.css';

// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // loading: '/assets/lazy.png',
  loading: '/assets/user.jpg',
  error: '/assets/user.jpg',
  attempt: 1
})

import VueLazyLoadVideo from 'vue-lazyload-video'
Vue.use(VueLazyLoadVideo)

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

import VueSwal from 'vue-swal'
Vue.use(VueSwal)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import VueViewer from 'v-viewer'
Vue.use(VueViewer)
import 'viewerjs/dist/viewer.css'

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import Sortable from 'vue-sortable'
Vue.use(Sortable)

import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBSbVWE1vEzKl7oGd5UOSet23TtwRIAK6o', // Replace with your API key
    libraries: 'places', // You can add more libraries if needed
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
