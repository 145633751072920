<template>
  <div id="app">
    <router-view class="bootstrap-wrapper" />
  </div>
</template>

<script>
var hours = 1; // Reset when storage is more than (1=60mins)(0.1=6mins)
var now = new Date().getTime();
var setupTime = localStorage.getItem('_setupTime_');
if (setupTime == null) {
  localStorage.setItem('_setupTime_', now)
} else {
  if (now - setupTime > hours * 60 * 60 * 1000) {
    // localStorage.clear()
    // .......................................
    localStorage.removeItem("_languages_");
    localStorage.removeItem("_countries_");
    localStorage.setItem('_setupTime_', now);
  }
}

export default {
  name: 'App',
  beforeCreate() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      console.log("%c It's a local server! ", 'background: #222; color: #bada55');
    } else {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }
  },
  created() {
    // console.log(this.$route.query.m_id);
    // console.log(this.$route.query.m_password);

    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    let m_id = params.get("m_id");
    let m_password = params.get("m_password");
    if (m_id && m_password) this.auto_login_after_register(m_id, m_password);
  },
  // mounted() {
  //   ...
  // },
  methods: {
    auto_login_after_register(m_id, m_password) {
      console.log(m_id, m_password);
      var url = this.api + 'login/member_login_after_register/' + this.secret;
      var data = new FormData();
      data.set('m_id', m_id);
      data.set('m_password', m_password);
      this.Axios.post(url, data)
        .then((response) => {
          if (response.data.error == 0) {
            let accountData = response.data.data;

            this.save_token(accountData);
            this.$swal("Successful Login", "", "success");
            this.redirect("/members");
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
  }
}
</script>

<style>
@import url(//fonts.googleapis.com/css?family=Raleway:300);

#app {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  align-items: center !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.swal-text {
  text-align: center !important;
}

.swal-footer {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.multiselect__tag {
  background: #3273dc !important;
}

.multiselect__tag-icon:after {
  color: #fff !important;
}

.multiselect__content {
  margin: 0 !important;
}

.show-mobile {
  display: none !important;
}

@media screen and (max-width: 767px) {

  /* (max-width: 600px) */
  .hidden-mobile {
    display: none !important;
  }

  .show-mobile {
    display: block !important;
  }
}

@media (max-width: 576px) {

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}

/* ................................................. */
.transform-none {
  text-transform: none !important;
}

.cut-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  /* display: none !important; */
  /* width: 160px;  */
  /* height: 1.2em;  */
}

.photo-album {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  /* height: 175px !important; */
}

.photo-cover {
  width: 100%;
  height: 375px !important;
  object-fit: cover !important;
}

@media screen and (max-width: 992px) {

  /* (max-width: 767px) */
  .photo-cover {
    height: 275px !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-border-blue.show .modal-dialog {
  transform: none;
  border: 2px solid #024fa0;
  border-radius: 10px;
}

.modal.show {
  display: block !important;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.avatar-3xl {
  width: 245px !important;
  height: 245px !important;
}

.image-fit {
  object-position: center;
  object-fit: cover;
}

.two-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blur {
  background-color: rgba(245, 245, 245, 1);
  opacity: .01;
}

.swal-modal {
  border: 4px solid #024fa0;
  border-radius: 10px;
}

@media (min-width: 992px) {
  .mt-approval-info {
    margin-top: -2.5rem !important;
  }
}

@media (min-width: 576px) {
  .modal-mdx {
    max-width: 400px
  }
}

.text-black {
  color: black
}

.text-label {
  color: #5e6c84 !important;
  font-size: 16px;
}

.text-active,
.text-active::placeholder {
  color: #1ba098 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.text-blue {
  color: #024fa0 !important;
}

.btn-blue {
  color: white !important;
  background-color: #024fa0 !important;
}

.text-midnight-blue {
  color: #2C3E50 !important;
}

.text-slate-gray {
  color: #5E6C84 !important;
}

.text-teal{
  color: #1BA098 !important;
}

.text-coral{
  color: #FF6B6B !important;
}
</style>

<style>
#progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  margin-top: 10px;
}

#progress-bar {
  height: 100%;
  background-color: #545454 !important;
}

.ribbon {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border: 25px solid transparent;
  position: absolute;
  bottom: -1px;
  right: -36px;
  padding: 0 10px;
  width: 120px;
  color: white;
  font-family: sans-serif;
  size: 11px;
}

.ribbon .txt {
  position: absolute;
  top: -25px;
  left: 10px;
}

.ribbon.pending {
  border-top: 25px solid #fe9d18;
}

.ribbon.approve {
  border-top: 25px solid #53ac57;
}

.ribbon.reject {
  border-top: 25px solid #ec4a47;
}

.ribbon-sm {
  width: 1px !important;
  bottom: -30px !important;
  right: -23px !important;
  padding: 0 1px !important;
}
</style>