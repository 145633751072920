<script>
import Swal from 'sweetalert2';
import moment from 'moment'
var md5 = require('md5');

import { member } from './memberData.js';

export default {
  data() {
    return {
      needPassword: false,
      tokenPassword: "",
      loading_screen: true,
      screen_mode: "",
      gender: [
        { "id": 1, "name": "Male" },
        { "id": 2, "name": "Female" },
      ],
      rate: [100, 150, 200, 250, 300, 350, 400, 450, 500],
      age_range: [],
      union_status: [],
      delivery_cap: [],
      ethnicities: [],
      // languages: [],
      countries: [],
      vocal_type: [],
      // com_type: [],
      demo_cat: [],
      accents: [],
    }
  },
  // created(){
  //   this.age_range = this.LocalStorage("_age_range_",[]);
  //   this.union_status = this.LocalStorage("_union_status_",[]);
  //   this.delivery_cap = this.LocalStorage("_delivery_cap_",[]);
  //   this.ethnicities = this.LocalStorage("_ethnicities_",[]);
  //   this.languages = this.LocalStorage("_languages_",[]);
  //   this.countries = this.LocalStorage("_countries_",[]);
  //   this.vocal_type = this.LocalStorage("_vocal_type_",[]);
  //   this.com_type = this.LocalStorage("_com_type_",[]);
  //   this.demo_cat = this.LocalStorage("_demo_cat_",[]);
  //   this.accents = this.LocalStorage("_accents_",[]);
  // },
  methods: {
    LocalStorage(name, set = new Object()) {
      var taskArrayString = localStorage.getItem(name);
      if (taskArrayString !== null && typeof taskArrayString === "string") {
        // console.log("Succesfully retrieved 'tasks' and contents.");
      } else {
        // console.log("Succesfully retrieved 'tasks', contents were not a string or held no contents. Contents of 'tasks' reset to empty object");
        var emptyArray = JSON.stringify(set);
        localStorage.setItem(name, emptyArray);
        taskArrayString = localStorage.getItem(name);
      }
      return JSON.parse(taskArrayString);
    },
    alphabetically(array, field) {
      return array.sort(function (a, b) {
        if (a[field] < b[field]) { return -1; }
        if (a[field] > b[field]) { return 1; }
        return 0;
      })
    },
    sort_by_text_length(array) {
      return array.sort((a, b) => a.length - b.length);
    },
    set_session_memberData(data, lang = null, tutorial = null) {
      let acc = {
        id: data.id,
        code: data.code,
        username: data.username,
        password: data.password,
        fname: data.fname,
        lname: data.lname,
        img: data.img,
        cover: data.cover,
        company_type: data.company_type,
        token: data.token,
        dontShowUpdateNotify: data.dontShowUpdateNotify,
      }
      if (!lang || lang == '') acc.lang = 'en';
      if (tutorial == true) acc.tutorial = tutorial;
      localStorage.setItem("_memberData_", JSON.stringify(acc));
    },
    check_login(account) {
      // console.log("check_login", account, member);
      if (account.code == member.code) {
        if (account.token == member.token) {
          this.screen_mode = 'active';
        } else {
          this.screen_mode = 'invalid_token';
        }
        setTimeout(() => { this.loading_screen = false; }, 1000);
      } else {
        // this.redirect("/");
        this.logout();
      }
    },
    md5(val) {
      return md5(val);
    },
    redirect(path) {
      if (this.$route.path !== path) this.$router.push(path)
    },
    login_with_token() {
      let tokenData = this.LocalStorage("_tokenData_");
      // let memberData = this.LocalStorage("_memberData_");
      // let accountData = this.LocalStorage("_accountData_");
      // console.log( tokenData );
      // console.log( memberData, accountData );
      if (tokenData.email && tokenData.token && tokenData.type) {
        this.login_token(tokenData);
      }
    },
    async login_token(tokenData) {
      // console.log(tokenData);

      var url = this.api + 'login/token/' + this.secret;
      var data = new FormData();
      data.set('email', tokenData.email);
      data.set('token', tokenData.token);
      data.set('type', tokenData.type);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            let acc = response.data.data;
            this.save_token(acc);
            switch (tokenData.type) {
              case "personal_account":
                // localStorage.setItem("_accountData_",JSON.stringify(acc));
                // this.accountData = acc;
                // this.redirect("/personal");
                this.redirect("/personal/update_profile");
                break;
              case "members":
                // localStorage.setItem("_memberData_",JSON.stringify(acc));
                // this.memberData = acc;
                this.redirect("/members");
                break;
            }
            // this.$swal("Successful Login ","","success");
            this.$emit("close");
          } else {
            this.needPassword = true;
          }
        })
        .catch((error) => {
          this.$swal("", "", "warning");
          console.error(error);
        })
        .then(() => {
          this.is_loading = false;
        })
    },
    save_token(data) {
      let tokenData = new Object();
      tokenData.email = data.m_username;
      tokenData.m_img = data.m_img;
      tokenData.token = data.token;
      tokenData.type = data.type;
      localStorage.setItem("_tokenData_", JSON.stringify(tokenData));
    },
    set_data(data, array) {
      if (Array.isArray(data)) return data;

      let Val = [];
      let Data = data.split(",");
      Data.forEach((e) => {
        let index = array.findIndex(x => x.id == e);
        if (index !== -1) Val.push(array[index]);
      });
      return Val
    },
    update_account_data(name, array) {
      let account = this.LocalStorage(name);
      Object.keys(array).forEach((e) => {
        account[e] = array[e];
      });
      localStorage.setItem(name, JSON.stringify(account));
    },
    profile_image(path) {
      if (path == "") path = "../assets/user.jpg";

      return path;
    },
    r_com_type(company) {
      let text = company.com_type;
      if (this.com_type.length) {
        let index = this.com_type.findIndex((e) => parseInt(e.id) == parseInt(company.com_type));
        if (index > -1) return this.com_type[index].name;
      } else {
        return text;
      }
    },
    logout_personal() {
      let acct = this.sessionData;
      if (this.isFreeAccount(acct)) {
        if (acct.paypal_status !== "Approved Profiles" && !parseInt(acct.approval)) {
          this.ask_for_aprroval();
        } else {
          this.ask_for_logout();
        }
      } else {
        this.ask_for_logout();
      }
    },
    ask_for_aprroval() {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to submit your profile \n for approval before logging out?",
        icon: "info",
        closeOnClickOutside: false,
        buttons: {
          Yes: true,
          cancel: "Later",
        },
      })
        .then((value) => {
          console.log(value);
          if (value) {
            this.redirect("/personal/update_profile");
            setTimeout(() => {
              document.getElementById("approval_btn").click()
            }, 500);
          } else {
            this.redirect("/");
          }
        });
    },
    ask_for_logout() {
      this.$swal(`Are you sure you want to logout?`, ``, "warning", { buttons: ['Stay logged in', `Log Out`], dangerMode: true })
        .then((ok) => {
          if (ok) this.logout();
        });
    },
    logout() {
      localStorage.setItem("_memberData_", JSON.stringify(new Object));
      setTimeout(() => window.location.href = '/', 1);
    },
    uniq_id() {
      var n = Math.floor(Math.random() * 11);
      var k = Math.floor(Math.random() * 1000000);
      var m = String.fromCharCode(n) + k;
      return m;
    },
    isFreeAccount(acct = null) {
      if (!acct) {
        return false;
      } else if (acct.payment_provider == "free_account" && acct.m_img == "") {
        if (parseInt(acct.m_sex) == 1) {
          acct.m_img = "../app/personal_account/profile/maleart.png";
        } else if (parseInt(acct.m_sex) == 2) {
          acct.m_img = "../app/personal_account/profile/femaleart.png";
        }
        let tokenData = this.LocalStorage("_tokenData_");
        acct.type = tokenData.type;
        // tokenData.m_img = acct.m_img;
        this.save_token(acct);

        return true;
      }
      return (acct.payment_provider == "free_account");
    },
    isAccountActive(acct = null) {
      console.log(acct);
      return true;
      // if(!acct)return false;
      //
      // let active = false;
      // let m_class = parseInt(acct.m_class);
      // let m_pay_status = parseInt(acct.m_pay_status);
      // let free_account = this.isFreeAccount(acct);
      //
      // if(free_account){
      //   active=true;
      // }else if(m_class==3){
      //   active=true;
      // }else if(m_pay_status==2 || m_pay_status==5){
      //   active=true;
      // }else{
      //   const minutesToAdjust = 60*24;
      //   const millisecondsPerMinute = 60000;
      //
      //   let now =  new Date();
      //   let today = new Date( now + (minutesToAdjust*millisecondsPerMinute) ).getTime();
      //   // let next_bill = moment(acct.paypal_next_bill).valueOf();
      //   // let m_class_exp = moment(acct.m_class_exp).valueOf();
      //
      //   let payment_provider = acct.payment_provider.toLowerCase();
      //   switch (payment_provider) {
      //     case "paypal":
      //     // console.log(acct.paypal_next_bill,today,moment(acct.paypal_next_bill).valueOf(),today>moment(acct.paypal_next_bill).valueOf());
      //     if(today<moment(acct.paypal_next_bill).valueOf())active=true;
      //     break;
      //     case "stripe":
      //     // console.log(acct.m_class_exp,today,moment(acct.m_class_exp).valueOf(),today>moment(acct.m_class_exp).valueOf());
      //     if(today<moment(acct.m_class_exp).valueOf())active=true;
      //     break;
      //   }
      // }
      // return active;
    },
    r_cat_name(demo) {
      let text = demo.cat_id;
      if (this.demo_cat.length) {
        let index = this.demo_cat.findIndex((e) => parseInt(e.cat_id) == parseInt(demo.cat_id));
        if (index > -1) return this.demo_cat[index].cat_name;
      } else {
        return text;
      }
    },
    async get_com_type() {
      var url = this.api + 'dataOption/get_com_type/' + this.secret;
      var data = new FormData();
      data.set('s', this.secret);
      await this.Axios.post(url, data)
        .then((response) => {
          // console.log(response.data);
          if (response.data.error == 0) {
            this.com_type = response.data.data;
            localStorage.setItem("_com_type_", JSON.stringify(response.data.data));
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    updated_now() {
      var d = new Date,
        dformat = [d.getFullYear(),
        this.pad(d.getMonth() + 1, 2),
        this.pad(d.getDate(), 2)].join('-') + ' ' +
          [this.pad(d.getHours(), 2),
          this.pad(d.getMinutes(), 2),
          this.pad(d.getSeconds(), 2)].join(':');
      return dformat;
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    generateRandomString(n = 10) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      let result = '';
      for (let i = 0; i < n; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result
    },
    generateRandomDigit(n = 10) {
      const characters = '0123456789';

      let result = '';
      for (let i = 0; i < n; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result
    },
    getFirstDateOfNextQuarter(inputDate) {
      // Parse the input date
      const currentDate = new Date(inputDate);

      // Calculate the current quarter
      const currentQuarter = Math.floor(currentDate.getMonth() / 3) + 1;

      // Calculate the first month of the next quarter
      const nextQuarterFirstMonth = currentQuarter * 3;

      // Calculate the first day of the next quarter
      const nextQuarterFirstDate = new Date(currentDate.getFullYear(), nextQuarterFirstMonth, 1);

      // Format the result as "YYYY-MM-DD"
      const formattedResult = `${nextQuarterFirstDate.getFullYear()}-${(nextQuarterFirstDate.getMonth() + 1).toString().padStart(2, '0')}-01`;

      return formattedResult;
    },
    showStep(index, steps) {
      if (index >= steps.length) {
        return;
      }

      let step = steps[index];
      let targetElement = null;
      if (step.selector) {
        targetElement = this.$refs[step.selector];
      }
      let redirect = step.redirect ?? null;
      let title = step.title ?? null;
      let html = step.html ?? null;
      let icon = step.icon ?? null;

      if (redirect) {
        this.redirect(redirect);
      }
      setTimeout(() => {
        Swal.fire({
          title: title,
          html: html,
          icon: icon,
          confirmButtonText: 'Next',
          allowOutsideClick: false,
          customClass: {
            popup: 'tutorial-popup'
          },
          didOpen: () => {
            if (targetElement) {
              const popup = Swal.getPopup();
              popup.style.position = 'absolute';

              // Get the position of the target element
              const targetRect = targetElement.getBoundingClientRect();

              // Get the position of the popup
              const popupRect = popup.getBoundingClientRect();

              // Calculate the position to center the popup on the target element
              const top = targetRect.top - popupRect.height - 10;
              const left = targetRect.left + (targetRect.width - popupRect.width) / 2;

              // Position the popup
              popup.style.top = `${top}px`;
              popup.style.left = `${left}px`;
              // Adjust the z-index of the backdrop
              const backdrop = document.querySelector('.swal2-backdrop');
              if (backdrop) {
                const backdropZIndex = parseInt(window.getComputedStyle(targetElement).zIndex) - 1;
                backdrop.style.zIndex = backdropZIndex;
              }
            }
          }
        }).then(() => {
          this.showStep(index + 1, steps);
        });
      }, 100);
    }
  },
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM YYYY');
    },
    date_short: function (date) {
      return moment(date).format('D MMM') + " (PST)";
    },
    date_message: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a');
    },
    date_project: function (date) {
      return moment(date).format('MMMM Do, YYYY h:mm a') + " (PST)";
    },
    date_approval: function (date) {
      return moment(date).format('D MMM, YYYY h:mm a') + " (PST)";
    },
    date_chat_message: function (date) {
      return moment(date).format('h:mm');
    },
    date_payout: function (date) {
      return moment(date).format('Do, MMMM YYYY');
    },
    date_list: function (date) {
      var fromNow = moment(date).fromNow();
      return moment(date).calendar(null, {
        lastWeek: '[Last] dddd',
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    },
    highlight: function (words, query) {
      return words.replace(query, '<span class="highlight">' + query + '</span>')
    }
  }
};
</script>

<style>
.border-img {
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  padding: 5px;
}
</style>